import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'testimonios',
    loadChildren: () => import('./testimonios/testimonios.module').then( m => m.TestimoniosPageModule)
  },
  {
    path: 'mapas',
    loadChildren: () => import('./mapas/mapas.module').then( m => m.MapasPageModule)
  },
  {
    path: 'arboles-ciudadanos',
    loadChildren: () => import('./arboles-ciudadanos/arboles-ciudadanos.module').then( m => m.ArbolesCiudadanosPageModule)
  },
  {
    path: 'instrucciones',
    loadChildren: () => import('./instrucciones/instrucciones.module').then( m => m.InstruccionesPageModule)
  },
  {
    path: 'mapa-plaza-ohiggins',
    loadChildren: () => import('./mapa-plaza-ohiggins/mapa-plaza-ohiggins.module').then( m => m.MapaPlazaOhigginsPageModule)
  },
  {
    path: 'mapa-plaza-bonilla-lo-lillo',
    loadChildren: () => import('./mapa-plaza-bonilla-lo-lillo/mapa-plaza-bonilla-lo-lillo.module').then( m => m.MapaPlazaBonillaLoLilloPageModule)
  },
  {
    path: 'hc-audios',
    loadChildren: () => import('./hc-audios/hc-audios.module').then( m => m.HcAudiosPageModule)
  },
  {
    path: 'hc-ohiggins',
    loadChildren: () => import('./hc-ohiggins/hc-ohiggins.module').then( m => m.HcOhigginsPageModule)
  },
  {
    path: 'hc-bonilla',
    loadChildren: () => import('./hc-bonilla/hc-bonilla.module').then( m => m.HcBonillaPageModule)
  },
  {
    path: 'mapa-ohiggins',
    loadChildren: () => import('./mapa-ohiggins/mapa-ohiggins.module').then( m => m.MapaOhigginsPageModule)
  },
  {
    path: 'mapa-bonilla',
    loadChildren: () => import('./mapa-bonilla/mapa-bonilla.module').then( m => m.MapaBonillaPageModule)
  },
  {
    path: 'ohiggins-modal',
    loadChildren: () => import('./ohiggins-modal/ohiggins-modal.module').then( m => m.OhigginsModalPageModule)
  },
  {
    path: 'test-map',
    loadChildren: () => import('./test-map/test-map.module').then( m => m.TestMapPageModule)
  },
  {
    path: 'bonilla-modal',
    loadChildren: () => import('./bonilla-modal/bonilla-modal.module').then( m => m.BonillaModalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
